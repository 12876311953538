
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 840px;
    margin: 0 auto;
    @media (max-width: 1250px) {
      max-width: 63%;
    }
    @media (max-width: 900px) {
      max-width: 89%;
    }
    &.paddingtop{
      padding-top: 190px;
      @media (max-width: 900px) {
        padding-top: 18rem;
      }
      @media (max-width: 750px) {
        padding-top: 26rem;
      }
      @media (max-width: 600px) {
        padding-top: 30rem;
      }
    }
    &.paddingbottom{
      padding-bottom: 100px;
      @media (max-width: 900px) {
        padding-bottom: 10rem;
      }

    }
}
