
$container: 1500px;
$container_small: 1500px;

$regular-font: "PxGrotesk-Regular";
$regular-font-bold: "PxGrotesk-Bold";

$white: #fff;
$black: #000;
$dark-grey: #444;
$footer-grey: #999;
$yellow: #F7FB02;

$highlight-grey: #EAEAEB;
$highlight-yellow: #F7FB02;



$imgpath: "../../../../assets/img/";
