@font-face {
  font-family: "PxGrotesk-Regular";
  src: url("../webfonts/Px-Grotesk-Regular-font.eot"); /* IE9 Compat Modes */
  src: url("../webfonts/Px-Grotesk-Regular-font.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../webfonts/Px-Grotesk-Regular-font.otf") format("opentype"), /* Open Type Font */
    url("../webfonts/Px-Grotesk-Regular-font.svg") format("svg"), /* Legacy iOS */
    url("../webfonts/Px-Grotesk-Regular-font.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../webfonts/Px-Grotesk-Regular-font.woff") format("woff"), /* Modern Browsers */
    url("../webfonts/Px-Grotesk-Regular-font.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PxGrotesk-Bold";
  src: url("../webfonts/Px-Grotesk-Bold.eot"); /* IE9 Compat Modes */
  src: url("../webfonts/Px-Grotesk-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../webfonts/Px-Grotesk-Bold.otf") format("opentype"), /* Open Type Font */
    url("../webfonts/Px-Grotesk-Bold.svg") format("svg"), /* Legacy iOS */
    url("../webfonts/Px-Grotesk-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../webfonts/Px-Grotesk-Bold.woff") format("woff"), /* Modern Browsers */
    url("../webfonts/Px-Grotesk-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}


h1 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 25px;;
  .highlight.grey {
    background: linear-gradient(to top, transparent 2.2rem, $highlight-grey 2%, $highlight-grey 96%, transparent 1%);

  }
  @media (max-width: 1250px) {
    font-size: 5.5rem;
  }
  @media (max-width: 900px) {
    font-size: 8rem;
    margin-bottom: 6rem;
  }
  @media (max-width: 750px) {
    font-size: 10.8rem;
    margin-bottom: 7rem;
  }
  @media (max-width: 600px) {
    font-size: 14.5rem;
    margin-bottom: 11rem;
  }
}

a {
  color: $black;
  text-decoration: none;
}

strong {
  font-weight: 800;
}

p, .content p, .details-inner p {
  font-size: 1.3rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;

  @media (max-width: 1250px) {
    font-size: 1.7rem;
    line-height: 1.5;
    margin-bottom: 3rem;
  }
  @media (max-width: 900px) {
    font-size: 3rem;
    margin-bottom: 5rem;
  }
  @media (max-width: 750px){
    font-size: 4rem;
    margin-bottom: 6rem;
  }
  @media (max-width: 600px){
    font-size: 4.5rem;
    margin-bottom: 7rem;
  }
  &.small{
    font-size: .8rem;
  }
  a{
    text-decoration: underline;
  }
}

.highlight {
  &.yellow {
    background: linear-gradient(to top, transparent 0.5rem, $highlight-yellow 5%, $highlight-yellow 70%, transparent 1%);
  }
  &.grey {
    background: linear-gradient(to top, transparent 0.5rem, $highlight-grey 5%, $highlight-grey 70%, transparent 1%);
  }
}

.content{
  h2{
    font-size: 2rem;
    margin-bottom: 1.3rem;
    @media (max-width: 1250px) {
      font-size: 3rem;
    }
    @media (max-width: 900px) {
      font-size: 4.5rem;
      margin-bottom: 4rem;
    }
    @media (max-width: 750px){
      font-size: 7rem;
      margin-bottom: 6rem;
      line-height: 1.4;
    }
    @media (max-width: 600px){
      margin-bottom: 10rem;
      line-height: 1.2;
    }
  }

}

.main-title {
  .highlight.grey {
    background: linear-gradient(to top, transparent 1.7rem, $highlight-grey 2%, $highlight-grey 96%, transparent 1%);
    @media (max-width: 900px){
      background: linear-gradient(to top, transparent 3.7rem, $highlight-grey 2%, $highlight-grey 96%, transparent 1%);
    }
    @media (max-width: 750px){
      background: linear-gradient(to top, transparent 5.7rem, $highlight-grey 2%, $highlight-grey 96%, transparent 1%);
    }
    @media (max-width: 600px){
      background: linear-gradient(to top, transparent 6.7rem, $highlight-grey 2%, $highlight-grey 96%, transparent 1%);
    }
  }
}


.link-underline {
  text-decoration: none !important;
  position: relative;

  &:before {
    position: absolute;
    bottom: -4px;
    content: ' ';
    height: 4px;
    width: 100%;
    background-color: $black;
    transition: all .4s ease;
  }
  &:hover{
    &:before {
      width: 80%;
      transition: all .4s ease;
    }

  }
}

.large-text{
  font-size: 2.2rem !important;
  @media (max-width: 900px) {
    font-size: 4rem !important;
  }
  @media (max-width: 750px){
    font-size: 8rem !important;
  }
}
