html {
  box-sizing: border-box;
  font-size: 16px;
  @media (max-width: 1250px) {
    font-size: 1vw;
  }
}

body {
    transition: background-color 300ms ease;
    background-color: $yellow;
    //font-family: 'Px Grotesk', sans-serif;
    //font-weight: 300;
    font-family:PxGrotesk-Regular;
    &.modal-open  {
      height: 100vh;
      overflow-y: hidden;
    }
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

strong {
  font-family:PxGrotesk-Bold;
}

.relative{
  position: relative;
}
