
/* Burger icon */
a.burger .core, a.burger .core:before, a.burger .core:after {
  display: block;
  position: absolute;
  width: 22px;
  height: 3px;
  background-color: $black;
}

/* Close icon */
a.burger .closebtn, a.burger .closebtn:before, a.burger .closebtn:after {
  display: block;
  position: absolute;
  width: 22px;
  height: 3px;
  background-color: #fff;
}

a.burger {
  position: fixed;
  right: 2rem;
  top: 2rem;
  z-index: 1;
  display: inline-block;
  width: 22px;
  height: 25px;
  @media (max-width: 1250px) {
    right: 2.6rem;
    top: 2.6rem;
  }
  @media (max-width: 900px) {
    right: 4rem;
    top: 4rem;
  }
  @media (max-width: 750px){
    right: 4.5rem;
    top: 4.5rem;
  }
  @media (max-width: 600px){
    right: 5.5rem;
    top: 6.5rem;
  }
  .core {
    top: 50%;
    margin-top: -1px;
    transition: all 150ms ease-in-out;

    &:before {
      content: '';
      top: -8px;
      transition: top 300ms ease-in-out 300ms, transform 150ms ease-out 0ms;
    }
    &:after {
      content: '';
      bottom: -8px;
      transition: bottom 300ms ease-in-out 300ms, transform 150ms ease-out 0ms;
    }
    &.closebtn {
      background-color: transparent;
      &:before {
        top: 0;
        transform: rotate(45deg);
        transition: top 300ms ease-in-out 0ms, transform 150ms ease-out 300ms;
      }
      &:after {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom 300ms ease-in-out 0ms, transform 150ms ease-out 300ms;
      }
    }
  }

}
.white{
  a.burger .core, a.burger .core:before, a.burger .core:after {
    background-color: #fff;
  }
  .active{
    a.burger .core, a.burger .core:before, a.burger .core:after {
      background-color: $black;
    }
  }

}



/* Menu */
.primary-nav {
  display: block;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0;
  width: 100%;
  background-color: $white;
  text-align: left;
  overflow: unset;
  ul {
    list-style: none;
    display: block;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 20px;
    @media (max-width: 1250px) {
      margin-left: 23%;
      max-width: none;
      width: 70%;
    }
    @media (max-width: 900px) {
      margin-left: 40px;
      width: 70%;
    }
    @media (max-width: 750px){
      margin: 0 auto;
      width: 90%;
      margin-bottom: 6rem;
    }
    @media (max-width: 600px){

    }
    li{

      a {
        text-decoration: none;
        position: relative;
        font-size: 5rem;
        line-height: 1;
        width: 100%;
        @media (max-width: 1250px) {
          font-size: 6.6rem;
        }
        @media (max-width: 900px) {
          font-size: 8.6rem;
        }
        @media (max-width: 750px){
          font-size: 11rem;
          line-height: 1.25;
        }
        @media (max-width: 600px){
          font-size: 12rem;
          line-height: 1.25;
        }
        span{
          z-index: 10;
          position: relative;
        }

        &:before {
          position: absolute;
          bottom: 10px;
          content: ' ';
          height: 20px;
          width: 0%;
          background-color: $highlight-grey;
          transition: all .4s ease;
          z-index: 2;
        }
        &.active{
          &:before {
            position: absolute;
            bottom: 10px;
            content: ' ';
            height: 20px;
            width: 100%;
            background-color: $highlight-yellow;
            transition: all .4s ease;

          }
        }
        &:hover{
          &:before {
            position: absolute;
            bottom: 10px;
            content: ' ';
            height: 20px;
            width: 100%;
            background-color: $highlight-yellow;
            transition: all .4s ease;

          }

        }
      }


    }
  }
  .menu-content {
    padding: 15px;
    font-size: 1.2rem;
    max-width: 800px;
    text-align: left;
    margin: 0 auto;
    padding: 1rem 7rem 0 0;
    @media (max-width: 1250px) {
      margin-left: 23%;
      width: 70%;
      max-width: none;

    }
    @media (max-width: 900px) {
      margin-left: 40px;
      width: 81%;

    }
    @media (max-width: 750px){
      margin: 0 auto;
      width: 90%;
    }
    @media (max-width: 600px){

    }
    p {
      margin-bottom: 15px;
      text-align: left;
      @media (max-width: 900px) {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
      @media (max-width: 750px){
        font-size: 3rem;
        margin-bottom: 3rem;
      }
      @media (max-width: 600px){
        font-size: 4.5rem;
        margin-bottom: 4.5rem;
      }
      &.small{
        font-size: 1rem;
        @media (max-width: 900px) {
          font-size: 124%;
        }

        @media (max-width: 750px) {
          font-size: 194%;
        }

        @media (max-width: 600px){
          font-size: 250%;
        }
      }
    }

  }
}

.valign{
  display:flex;
  justify-content:center;
  align-items:center;
  height: 0;
  min-height: 0;
  padding-top: 8rem;
  @media (max-width: 1330px) {
    display:block;
    justify-content:none;
    align-items:none;
    height: 100%;
    padding-top: 100px;
  }

}


.nav-open {
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  header {
    background-color: $white;
    transition: background-color 300ms ease;
  }
  nav{
    position: fixed;
    @media (max-width: 900px) {
      position: absolute;
      background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%);
    }

    .core, .closebtn {
      background-color: transparent;
      &:before {
        top: 0;
        transform: rotate(45deg);
        transition: top 300ms ease-in-out 0ms, transform 150ms ease-out 300ms;
      }
      &:after {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom 300ms ease-in-out 0ms, transform 150ms ease-out 300ms;
      }
    }
  }
  .primary-nav {
      opacity: 1;
      height: 100%;
      min-height: 100vh;
      transition: opacity 300ms ease-in-out;
      overflow: auto;
      pointer-events: initial;
      @media (max-width: 900px) {
        max-height: none;
      }
  }
  .valign{
    height: auto;
    min-height: calc( 100vh - 170px );
  }
}

.nav-footer {
    background-color: $black;
    color: $footer-grey;
    width: 100%;
    position: relative;
    padding: 30px;
    margin-top: 5rem;
    @media (max-width: 900px) {
      padding: 6rem 5%;
    }
    .nav-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 900px) {
        flex-direction: column;
        align-items: left;
        padding: 4rem 0;
      }
      div:first-of-type {
        display: flex;
        align-items: center;
        @media (max-width: 900px) {
          flex-direction: column;
          align-items: start;
          text-align: left;
        }
        p {
          max-width: 480px;
          line-height: 1.3;
          margin-left: 20px;
          @media (max-width: 900px) {
            margin-left: 0;
            margin-top: 3rem;
            max-width:100%;
          }
          @media (max-width: 750px) {
            margin-top: 6rem;
          }
        }
      }

      div {
        max-width: 50%;
        @media (max-width: 900px) {
          max-width: 100%;
        }
      }

      div.legal {
        padding-top: 0;
        text-align: center;
        @media (max-width: 900px) {
          text-align: left;
          width: 100%;
        }
      }
    }
    p {
      font-size: 11px;
      letter-spacing: 1.2;
      a{
        color: $footer-grey;
      }
    }
}

.partners{
  display: flex;
  margin: 0 -1rem;
  padding-top: 2rem;
  @media (max-width: 750px) {
    margin: 0 -3rem;
  }
  div{
    padding: 1rem;
    display:flex;
    justify-content:center;
    align-items:center;
    @media (max-width: 750px) {
      padding: 3rem;
    }
  }
}
