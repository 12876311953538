.Home {
    background-color: $yellow;
    transition: background-color 300ms ease;
    .showcase {
      display: flex;
      align-items: center;
      min-height: 100vh;
      @media (max-width: 750px) {
        padding-top: 20rem;
      }
      h1{
        font-size: 5rem;
        line-height: 1;
        margin-bottom: 35px;
        @media (max-width: 1250px) {
          font-size: 6.5rem;
        }
        @media (max-width: 900px) {
          font-size: 8rem;
        }
        @media (max-width: 750px) {
          font-size: 10.8rem;
        }
        @media (max-width: 600px) {
          font-size: 12.5rem;
        }
        strong{
          font-weight: 400;
          font-family: "PxGrotesk-Regular";
          background: linear-gradient(to top, transparent 2.2rem, $highlight-grey 2%, $highlight-grey 96%, transparent 1%);

        }
      }
      h3 {
        font-size: 2.2rem;
        @media (max-width: 1250px) {
          font-size: 1.7rem;
          line-height: 1.5;
        }
        @media (max-width: 900px) {
          font-size: 3rem;
        }


        @media (max-width: 750px){
          font-size: 4rem;
          margin-bottom: 6rem;
        }
        @media (max-width: 600px){
          font-size: 5.5rem;
          margin-bottom: 7rem;
        }
      }


      .wrapper {
        @media (max-width: 900px) {
          max-width: 80%;
        }

      }
    }
}
@keyframes blink {
   50% { border-color: $yellow; }
}
.txt-rotate > .wrap{
  border-right: 0.08em solid #000 !important;
  animation: blink .5s step-end infinite alternate;
}
