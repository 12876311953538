

.tabs {
  margin-left: 0;
  list-style: none;
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  @media (max-width: 750px) {
    display: inline-block;
  }
  li{
    width: 33.333%;
    position: relative;
    @media (max-width: 750px) {
      font-size: 4.8rem;
      margin-bottom: 4rem;
      width: 100%;
    }
    &:after {
      position: absolute;
      bottom: -4px;
      text-align: left;
      content: ' ';
      height: 4px;
      width: 100%;
      background-color: $black;
      transition: all .4s ease;
      z-index: 10;
    }
    a{
      text-align: center;
      display: block;
      width: 100%;
      padding: 0 0 3px 0;
      font-size: 20px;
      z-index: 20;
      position: relative;
      @media (max-width: 1250px) {
        font-size: 1.5rem;
      }
      @media (max-width: 900px) {
        font-size: 2.2rem;
      }
      @media (max-width: 750px) {
        font-size: 4.8rem;
        text-align: left;
      }
      @media (max-width: 600px) {
        font-size: 4.8rem;
      }
    }
    &.index-2{
      margin: 0 20px;
      @media (max-width: 750px) {
        margin: 4rem 0;
      }
    }

    &.active {
      font-weight: 600;
      &:after {
        position: absolute;
        bottom: -4px;
        text-align: left;
        content: ' ';
        height: 20px;
        width: 100%;
        background-color: $highlight-grey;
        transition: all .4s ease;

      }

    }

  }
  .nav-tabs{
    *zoom: 1;
  }
}


.nav > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}



.tab-content.active{
  opacity: 1;
  height: auto;
  display: block;
}

.tab-content.hide{
  transition: all .2s ease;
  opacity: 0;
  height: 0;
  display: none;
}


  .masonry-layout {
    column-count: 3;
    column-gap: 0;
    &.two-col{
      column-count: 2;
    }
    @media (max-width: 900px) {
      column-count: 2;
      column-gap: 0;
      &.two-col{
        column-count: 1;
      }
    }
    @media (max-width: 750px) {
      column-count: 1;
      column-gap: 0;
      &.two-col{
        column-count: 1;
      }
    }
  }
  .masonry-layout__panel {
    break-inside: avoid;
    padding: 20px;
    @media (max-width: 900px) {
      padding: 3rem;
    }
    @media (max-width: 750px) {
    }
    @media (max-width: 600px) {
      padding: 6rem 0;
    }
    .img-wrapper{
      position: relative;
      img {
          width: 100%;
          height: auto;
      }
      .card-info{
        width: 100%;
        height: 100%;
        position: relative;
        transition: all .5s ease;

        .card-content{
          position: absolute;
          top: 0;
          left: 0;
          background-image:url('/assets/img/bg_hover.png');
          display: block;
          width: 100%;
          height: 100%;
          margin-top: 0;
          opacity: 0;
          box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.51);
          transition: all .4s ease;
          .number{
            position: absolute;
            top: 0px;
            right: 8px;
            font-size: 45px;
          }
          .details{
            position: absolute;
            bottom: 10px;
            width: 100%;
            left: 10px;
            font-size: 11px;
            h3{
              font-size: 28px;
              padding: 20px;
              width: 65%;
              font-weight: 300;
              line-height: 1.2;
              @media (max-width: 1250px) {
                font-size: 2.5rem;
              }
              @media (max-width: 900px) {
                font-size: 4rem;
                margin-bottom: 4rem;
              }
              @media (max-width: 750px){
                font-size: 7.5rem;
                margin-bottom: 6rem;
                line-height: 1.4;
              }
              @media (max-width: 600px){
                font-size: 9.5rem;
                margin-bottom: 10rem;
                line-height: 1.2;
              }
            }
          }
        }
        &:hover {
          transform: translate3D(0,-1px,0) scale(1.1) ; /* move up slightly and zoom in */
          transition: all .4s ease; /* zoom in */

          cursor: pointer;
          z-index: 10;
          .card-content{
            opacity: 1;
          }
          img{
            filter: grayscale(1);
          }
        }

      }
    }
  }
  .masonry-layout__panel-content {
    box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.51);
    img{
      width: 100%;
    }
  }
