.next-up {
  padding-top: 8rem;
  background: #EAEAEB ;
  @media (max-width: 1290px) {
    padding-top: 0;
  }
  .inner{
    width: 100%;
    position: relative;
    z-index: 100;
    padding-top: 5rem;
    background: linear-gradient(90deg, #EAEAEB 0%, #EAEAEB 10.01%, #fff 10.01%, #fff 100%);
    position: relative;
    @media (max-width: 1290px) {
      background: #fff;
    }
    @media (max-width: 750px) {
      padding-top: 15rem;
      padding-bottom: 9rem;
    }
    .content {
      max-width: 100%;
      margin-left: calc(50% - 420px);
      background-color: #fff;
      padding-bottom: 6rem;
      @media (max-width: 1290px) {
        margin:0 15%;
      }
      @media (max-width: 900px) {
        margin:0 5%;
      }

      h4{
        font-size: 1.4rem;
        @media (max-width: 1290px) {
          font-size: 1.75rem;
        }
        @media (max-width: 900px) {
          font-size: 2.5rem;
        }
        @media (max-width: 750px) {
          font-size: 3.5rem;
        }
        @media (max-width: 600px) {

        }
      }

      h3 {
        display: block;
        width: 100%;
        font-size: 4rem;
        @media (max-width: 1250px) {
          font-size: 6.5rem;
        }
        @media (max-width: 900px) {
          font-size: 8rem;
        }
        @media (max-width: 750px) {
          font-size: 11rem;
          line-height: 1;
        }
        @media (max-width: 600px) {

        }
        span.highlight {
          display: inline-block;
          width: 100%;
          padding-left: 5px;
        }
        span.arrow {
          float: right;
          padding-right: 125px;
        }
      }
      h3 ~ p {
        margin: 25px 0;
        max-width: 800px;
        @media (max-width: 750px) {
          margin: 5rem 0;
        }
      }
      p{
        font-size: 1.4rem;
        @media (max-width: 1250px) {
          font-size: 1.9rem;
        }
        @media (max-width: 900px) {
          font-size: 2.8rem;
        }
        @media (max-width: 750px) {

        }
        @media (max-width: 600px) {
          font-size: 3.8rem;
        }
      }
      a {
        text-decoration: none;
        position: relative;

        &:before {
          position: absolute;
          bottom: -4px;
          content: ' ';
          height: 4px;
          width: 100%;
          background-color: $black;
          transition: all .4s ease;
        }
        &:hover{
          &:before {
            width: 80%;
            transition: all .4s ease;
          }
        }
      }
    }
  }
}
