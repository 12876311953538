.thinkgallery {
    display: flex;
    flex-direction: row;
    padding: 5rem 10% 0;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    min-height: 800px;
    background: linear-gradient(180deg, $yellow 0%, $yellow 18.01%, $highlight-grey 10.01%, $highlight-grey 100%);
    @media (max-width: 900px) {
      padding: 5rem 5% 0;
    }
    @media (max-width: 750px) {

    }
    &.second{
      background: linear-gradient(180deg, $yellow 0%, $yellow 10.01%, $highlight-grey 10.01%, $highlight-grey 100%)!important;

    }
    &.third{
      background: linear-gradient(180deg, $yellow 0%, $yellow 33.01%, $highlight-grey 10.01%, $highlight-grey 100%)!important;

    }
}

.think_details{
  min-height: 100vh;
  height: auto;
  overflow: auto;
  width: 100vw;
  background: linear-gradient(90deg, $yellow 0%, $yellow 70.01%, $highlight-grey 70.01%, $highlight-grey 100%)!important;
  display:flex;
  justify-content:center;
  align-items:center;
  @media (max-width: 900px) {
    height: auto;
    min-height: 100vh;
    background: linear-gradient(180deg, $yellow 0%, $yellow 70.01%, $highlight-grey 70.01%, $highlight-grey 100%)!important;

  }
  .think-inner{
    width: 70%;
    margin: auto;
    display: flex;
    padding: 10% 0;
    @media (max-width: 1250px){
      display: block;
      width: 85%;
    }
    @media (max-width: 900px){
      display: block;
      padding: 20rem 0;
    }
    .details-left{
      width: 60%;
      float: left;
      @media (max-width: 900px){
        width: 100%;
      }
      .details-inner{
        padding-right: 10%;
        p{
          &.ref{
            font-size: 140%;
            @media (max-width: 900px){
              font-size: 210%;
            }
            @media (max-width: 750px){
              font-size: 300%;
            }
          }
        }
        h1 {
          font-size: 3.5rem;
          font-weight: 300;
          line-height: 1.1;
          margin-bottom: 25px;;
          @media (max-width: 900px){
            font-size: 6.5rem;
          }
        }
      }

    }
    .image-right{
      width: 40%;
      float: right;
      @media (max-width: 900px){
        margin-top: 11rem;
        width: 100%;
      }
      .imagearea{
        position: relative;
        img{
          width: 100%;
          &.shadow{
          box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.51);
          }
        }
      }
      .ref{ 
        margin-top: 1.6rem;
        font-size: 1.1rem;
        @media (max-width: 1250px) {

        }
        @media (max-width: 900px) {
          font-size: 3rem;
          margin-top: 5rem;
        }

        @media (max-width: 600px) {
          font-size: 4rem;
          margin-top: 5rem;
        }
      }
    }
  }

}


.fancybox-button {
  color: #000;
  width: 54px;
  height: 54px;
  background: none;
  padding: 0;
}


.fancybox-button--close{
  position: absolute;
  top: 16px;
  right: 20px;
  width: 28px;
}

.fancybox-navigation {
  .fancybox-button {
    height: 38px;
    opacity: 0;
    padding: 6px;
    position: absolute;
    top: 24px;
    width: 38px;
    color: #000;
    &::after {
      content: '';
      left: auto;
      padding: 0;
      position: absolute;
      top: auto;
    }
  }
  .fancybox-button--arrow_left {
    right: 100px;
    color: #000;
    left: auto;
  }
  .fancybox-button--arrow_right {
    right: 66px;
    color: #000;
  }
}


.fancybox-is-open{
  .fancybox-bg {
      opacity: 1;
      background: linear-gradient(90deg, $yellow 0%, $yellow 70.01%, $highlight-grey 70.01%, $highlight-grey 100%)!important;
  }
}

.fancybox-slide--iframe{
  padding: 0;
}

.fancybox-slide--iframe .fancybox-content {
    background: #fff;
    height: 100% ;
    margin-bottom: 0;
}

.img-gallery{
  position: relative;
  div{
    width: 100%;
  }

}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: -35px;
  z-index: 3000;

  .gallerypaging {
    width: fit-content;

    ul.slick-dots {
      position: relative;
      bottom: 4px;
      line-height: 0;
      padding: 0 15px;
      margin: 0;
      li {
        padding: 0;
        margin: 0;
        display: inline-block;
        position: relative;
        background: none;

        button {
          position: absolute;
          top: 6px;
          left: 6px;
          height: 1px;
          width: 1px;
          border-radius: 50%;
          overflow: hidden;
          border: none;
          background: #20323f;
          margin: 0;
          padding: 5px;
          cursor: pointer;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background: #e72076;
          }
        }
      }
    }
  }

  #nxt-btn,
  #prev-btn {
    width: auto;
    height: 20px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    color: #616161;

    .fa-arrow-left,
    .fa-arrow-right {
      &::before {
        font-size: 22px;
      }
    }
  }
  .nxt-btn{
    margin-left: 20px;
  }
  .slick-disabled{
    opacity: .3;
  }
}

.hidecomments{
  opacity: 0;
  height: 0;
  background: #fff;
  padding: 1.5rem;
  transition: all .4s ease;
  &.comments-open{
    opacity: 1;
    height: auto;
    display: block;
    transition: all .4s ease;
  }
}
