/* Gallery on page Explore */
.gallery.explore {
    display: flex;
    flex-direction: row;
    padding: 5rem 10% 0;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    background: linear-gradient(180deg, $yellow 0%, $yellow 59.01%, $highlight-grey 10.01%, $highlight-grey 100%);
    @media (max-width: 1400px) {
      padding: 5rem 5% 0;
    }
    @media (max-width: 1300px) {
      padding: 5rem 5% 10rem;
    }
    @media (max-width: 900px) {
      flex-direction: column;
    }
    @media (max-width: 750px) {

    }
    @media (max-width: 600px) {

    }
    & > div {
      width: 50%;
      @media (max-width: 900px) {
        width: 100%;
      }
    }

    & > div:first-of-type {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: initial;
        padding-top: 9.5rem;
        @media (max-width: 900px) {
          width: 100%;
          padding-top: 0;
        }

        .audio-preview {
            display: block;
            background-color: $black;
            width: 100%;
            @media (max-width: 750px) {
                  margin-bottom: 8rem;
            }
        }

        .featured-photos {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 16px;
            @media (max-width: 900px) {
              margin-bottom: 2rem;
            }

            .img-wrapper {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              flex-basis: 23%;
              @media (max-width: 900px) {
                margin-bottom: 2rem;
              }
              @media (max-width: 750px) {
                flex-basis: 47%;
                margin-bottom: 6rem;
              }

              &:nth-of-type(odd) {
                margin-top: 16px;
                @media (max-width: 900px) {
                  margin-top: 0;
                }
                .card-content{
                  height: 100%;
                }
              }
            }

        }
    }

    .masonry {
      display: flex;
      flex-direction: row;
      width: 50%;
      margin-left: 15px;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 900px) {
        width: 100%;
        margin-left: 0;
      }

        .img-wrapper {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          flex-basis: 23%;
          @media (max-width: 900px) {
            margin-bottom: 2rem;
          }
          @media (max-width: 750px) {
            flex-basis: 47%;
            margin-bottom: 6rem;
          }

          &:nth-of-type(odd) {
            margin-top: 16px;
            @media (max-width: 900px) {
              margin-top: 0;
            }
            .card-content{
              height: 100%;
            }
          }
        }


    }
    .img-wrapper{
      position: relative;
      img {
          width: 100%;
          height: auto;
      }
      .card-info{
        width: 100%;
        height: 100%;
        position: relative;
        transition: all .5s ease;

        .card-content{
          position: absolute;
          top: 0;
          left: 0;
          background-image:url('/assets/img/bg_hover.png');
          display: block;
          width: 100%;
          height: calc(100% - 16px);
          margin-top: 0;
          opacity: 0;
          box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.51);
          transition: all .4s ease;
          @media (max-width: 900px) {
            height: 100% ;
          }
          .number{
            position: absolute;
            top: 0px;
            right: 8px;
            font-size: 2.5rem;
            @media (max-width: 1250px) {
              font-size: 2.3rem;
            }
            @media (max-width: 900px) {
              font-size: 4.3rem;
            }
            @media (max-width: 750px) {
              font-size: 8.3rem;
            }
            @media (max-width: 600px) {

            }
          }
          .details{
            position: absolute;
            bottom: 10px;
            width: 100%;
            left: 10px;
            font-size: 11px;
            h3{
              font-size: 1.5rem;
              line-height: 1.2;
              margin-bottom: 0.3rem;
              width: 100%;
              padding: 0 2rem 0 0;
              @media (max-width: 1250px) {
                font-size: 1.5rem;
              }
              @media (max-width: 900px) {
                font-size: 3.5rem;
              }
              @media (max-width: 750px) {
                font-size: 7rem;
              }
              @media (max-width: 600px) {

              }
            }
            p{
              font-size: .9rem;
              line-height: 1.5;
              margin-bottom: 0;
              padding: 0 2rem 0 0;
              @media (max-width: 1250px) {
                font-size: 1rem;

              }
              @media (max-width: 900px) {
                font-size: 2rem;
              }
              @media (max-width: 750px) {
                font-size: 4rem;
              }
              @media (max-width: 600px) {

              }
            }
          }
        }
        &:hover {
          transform: translate3D(0,-1px,0) scale(1.4) ; /* move up slightly and zoom in */
          transition: all .4s ease; /* zoom in */
          @media (max-width: 750px) {
            transform: translate3D(0,-1px,0) scale(1.2) ; /* move up slightly and zoom in */
          }
          cursor: pointer;
          z-index: 10;
          .card-content{
            opacity: 1;
          }
          img{
            filter: grayscale(1);
          }
        }

      }

    }


}
