.contribute-gallery{
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, $yellow 0%, $yellow 50.01%, $highlight-grey 50.01%, $highlight-grey 100%)!important;
  padding: 2rem 10% ;
  @media (max-width: 1100px) {
    padding: 2rem 6% 5rem;
  }
  @media (max-width: 900px) {
    max-width: 100%;
    margin: 0 auto;
    padding: 2rem 6% 9rem;
  }
  .contribute-gallery-slide{
    padding: 1rem;
    display:flex !important;
    justify-content:center;
    align-items:center;
    img,div{
      width: 100%;
    }
  }
  .ref{
    margin-top: 1.6rem;
    font-size: 1.1rem;
    @media (max-width: 1250px) {

    }
    @media (max-width: 900px) {
      font-size: 3rem;
      margin-top: 5rem;
    }

    @media (max-width: 600px) {
      font-size: 4rem;
      margin-top: 5rem;
    }
  }
}

.controls_contribute {
  display: flex;
  flex-direction: row;
  z-index: 3000;
  height: 100%;
  max-width: 840px;
  margin: 0 auto;
  justify-content: space-between;
  @media (max-width: 1250px) {
    max-width: 63%;
  }
  @media (max-width: 900px) {
    max-width: 89%;
    display: block;
  }
  h1{
    @media (max-width: 900px) {
      margin-bottom: 4rem;
    }
  }
  .gallerypaging {
    width: fit-content;

    ul.slick-dots {
      position: relative;
      bottom: 4px;
      line-height: 0;
      padding: 0 15px;
      margin: 0;
      li {
        padding: 0;
        margin: 0;
        display: inline-block;
        position: relative;
        background: none;

        button {
          position: absolute;
          top: 6px;
          left: 6px;
          height: 1px;
          width: 1px;
          border-radius: 50%;
          overflow: hidden;
          border: none;
          background: #20323f;
          margin: 0;
          padding: 5px;
          cursor: pointer;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background: #e72076;
          }
        }
      }
    }
  }

  #nxt-btn,
  #prev-btn {
    width: auto;
    height: 20px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    color: #616161;

    .fa-arrow-left,
    .fa-arrow-right {
      &::before {
        font-size: 22px;
      }
    }
  }
  .nxt-btn{
    margin-left: 20px;
  }
  .slick-disabled{
    opacity: .3;
  }
}
