form {
  width: 100%;

}

label {
  color: #20323F;
  display: none;
  &.show {
    display: block;
    line-height: 1.35;
    color: #000;
    font-size: 20px;
    margin-top: 34px;
  }
}

input,
select,
textarea {
  width: 100%;
  border-radius: 0;
  margin: 10px 0;
  font-size: 20px;
  padding: 18px 0;
  letter-spacing: 0;
  background: none;
  line-height: 1.35;
  font-family: "PxGrotesk-Bold";
  color: #000;
  border-bottom: 4px solid #000;

  &.required {
    border: 1px solid #8b0000;
  }

  &.form-control {
    border-radius: 0;
  }
  &::placeholder {
  color: #000;
  font-family: "PxGrotesk-Regular";
  }
  &:focus {
  outline-width: 0;
  outline: none;
  font-family: "PxGrotesk-Bold";
  }
}

textarea {
  min-height: 234px;
  background: #fcff5f;
  width: 100%;
  margin-top: 20px;
  padding: 30px;
  border-bottom: none
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  -webkit-appearance: none;
}



.dialog-item {
  button {
    margin-top: 10px;
  }
}

.validation-msg {
  padding: 20px 25px;
  margin: 10px auto;
  text-align: center;
  color: #262626;
  border: 1px solid #e0d8d8;
  border-radius: 3px;
  line-height: 1;
  background: #ffffcc;

  &.error {
    background: #f7e0e0;
  }

  @media (max-width: 900px) {
    padding: 15px 20px;
  }

  @media (max-width: 600px) {
    padding: 10px 15px;
  }

  @media (max-width: 450px) {
    padding: 7.5px 10px;
  }
}

.select_style {
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  background: #f4fbfd;
  border-radius: 3px;
  font-family: $regular-font;
  font-size: 14px;
  width: 100%;
  position: relative;
  border: 1px solid #d0cfcf;
  margin-bottom: 9px;
  font-weight: 700;
  color: #6f6f6f;

  @media (max-width: 900px) {
    font-size: 15px;
    line-height: 1em;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1em;
  }

  @media (max-width: 450px) {
    font-size: 12px;
    line-height: 1em;
  }


  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 100%;

    option {
      padding-right: 45px;
    }
  }
}

.error-input,
div.error-input {
  border: 1px red solid;
}

.error-msg {
  font-size: 14px;
  padding: 5px 0;
  display: none;
  color: red;
  text-align: right;
}

.alert{
  border-radius: 0.4rem;
  background-color: #feebc8;
  padding: 1rem 1rem 1.4rem;
  color: #fb697d;
  margin: 2rem 0 2.5rem;
}

.uploadarea{
  position: relative;
  float: left;
  width: 100%;

}
input[type="file"] {
  position: absolute;
  z-index: -1;
  top: -13px;
  left: 0px;
  font-size: 15px;
  color: rgb(153,153,153);
}



.button-wrap {
  position: relative;
}

.new-button {
    display: inline-block;
    padding: 8px 0;
    cursor: pointer;
    background-color: $yellow;
    font-size: 16px;
    color: #fff;
    width: auto;
    margin: 34px 0;
    margin-top: 0 !important;

    .black-btn{
      background: #000;
      display: inline-block;
      padding: 10px 25px;
      margin-left: 20px;
      color: #fff;
    }
}

.submit-btn{
  width: 100%;
  background: #000;
  display: inline-block;
  padding: 10px 25px;
  color: #fff;
  font-size: 28px;
  text-align:right;
  @media (max-width: 750px){
    text-align:center;
  }

}

.small-text{
  font-size: .9rem !important;
  padding: 20px 0 10px 0;
  text-align:right;
  @media (max-width: 1250px) {
    font-size: 1.2rem !important;
  }
  @media (max-width: 900px) {
    font-size: 2rem !important;
  }
  @media (max-width: 750px){
    font-size: 2.5rem !important;
  }
  @media (max-width: 600px){
    font-size: 4.5rem !important;
  }
}
