header {
  position: relative;
  z-index: 2000;
  pointer-events: none;
  .nav-wrapper {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

  }
  &.white{

  }
}
nav {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  z-index: 2010;
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 750px){
    margin: 0 auto;
    width: 100%;
    padding: 6%;
  }
  .burger {
    top: -5px;
  }
  .logo {
    img {
        max-width: 170px;
        height: auto;
    }
  }
}

.whitenav {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  z-index: 3000;
  position: absolute;
  top: 0;
  left: 0;
  .close-button {
    top: -5px;
  }
  .logo {
    img {
        max-width: 170px;
        height: auto;
    }
  }
}
