.video-container {
    position: relative;
    padding-bottom: 57.25%;
    overflow: hidden;
    height: 0;

    &.paused-state {
        .play {
            display: flex;
        }
    }
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: -1px;
    width: 100%;
    height: 100%;
}

.iframe-game{
  background: #000;
  position: fixed;
  top: 0;
  left: -1px;
  width: 100.5%;
  height: 100.5%;
  z-index: 2500;
  iframe {
      position: absolute;
      top: 0;
      left: -1px;
      width: 100.5%;
      height: 100.5%;
  }
}
